import { createContext, useContext, useState, useEffect, useRef } from "react";
import { ROUTES } from "../api/config";
import { arrayBufferToBase64 } from "../utils/Util";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { toastEmitter } from "../components/toast/toastManager";
import Mixpanel, { EventNames } from "../utils/analytics/mixpanel";
import useNetworkStatus, { NetworkStatus } from "../hooks/useNetworkStatus";
import useWakeLock from "../hooks/useWakeLock";
import useAudio, { AUDIO_PATH } from "../hooks/useAudio";

// To set the initial sample rate for audioContext
const SAMPLE_RATES = [16000, 22050, 32000, 44100, 48000];
var SAMPLE_RATE = 16000;

// for (const rate of SAMPLE_RATES) {
//   try {
//     // @ts-ignore
//     const audioContext = new (window.AudioContext || window.webkitAudioContext)(
//       { sampleRate: rate }
//     );
//     if (audioContext.sampleRate === rate) {
//       SAMPLE_RATE = rate;
//       audioContext.close();
//       break;
//     }
//     audioContext.close();
//   } catch (e) {
//     // Sample rate not supported
//   }
// }

type MiliStates =
  | "NOT_STARTED"
  | "IN_PROGRESS"
  | "RESUME"
  | "FINALIZING_NOTES"
  | "GENERATING_SUMMARY"
  | "GENERATING_FOLLOW_UP"
  | "COMPLETED"
  | "RECONNECTING"
  | "STOPPED"
  | null;

type HandleUpdateMeetingMaxTiming = ({
  data,
  action,
}: {
  data: {
    type: "TIMER";
    remainingTime: number;
  };
  action: "UPDATE" | "RESET";
}) => void;

type MaxTime = number | null;

const SocketContext = createContext<any>({});

export const useSocket = () => useContext(SocketContext);

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const MAX_RECONNECT_DEPTH = 2;

export const SocketProvider = ({ children }) => {
  const isConnectedRef = useRef<boolean>(false);
  const isSocketReadyToSendData = useRef<boolean>(false);
  const [activeMeetingId, setActiveMeetingId] = useState(null);
  const activeMeetingIdRef = useRef(null);
  const socketRef = useRef<WebSocket | null>(null);
  const [miliState, setMiliState] = useState<MiliStates>(null);
  const [meetingMaxTime, setMeetingMaxTime] = useState<MaxTime>(null);
  const isRecordingRef = useRef(false);
  const token = useSelector((state: RootState) => state.app.token);
  const dispatch = useDispatch<AppDispatch>();
  const [meetingExtended, setMeetingExtended] = useState<boolean>(false);

  // const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  // const [scriptProcessor, setScriptProcessor] = useState<ScriptProcessorNode | null>(null);
  const audioContextRef = useRef(null)
  const scriptProcessorRef = useRef(null)
  const streamRef = useRef<MediaStream | null>(null);
  const networkStatus: NetworkStatus = useNetworkStatus();
  const audioChunksRef = useRef([]);
  const networkStatusRef = useRef(null);
  const networkTimestamp = useRef(null);
  const miliStateRef = useRef<MiliStates>(null);
  const { requestWakeLock, releaseWakeLock } = useWakeLock();
  const { play } = useAudio();

  const reconnectAttemptsRef = useRef(0);
  const maxReconnectAttempts = 20;
  const initialReconnectDelay = 1000 * 15;

  // New refs for packet sequencing and acknowledgment
  const packetSequenceNumberRef = useRef(0);
  const sentPacketsRef = useRef<{ [key: number]: string }>({});
  const lastAckIdRef = useRef(-1);
  const maxPacketBufferSize = 300;
  const audioMetadataRef = useRef(null);
  const connectTimeoutRef = useRef(null);


  // Add a ref to track if audio context has been initialized
  const audioContextInitialized = useRef(false);

  const initializeAudioContext = () => {
    for (const rate of SAMPLE_RATES) {
      try {
        // @ts-ignore
        const audioContext = new (window.AudioContext || window.webkitAudioContext)(
          { sampleRate: rate }
        );
        if (audioContext.sampleRate === rate) {
          SAMPLE_RATE = rate;
          console.log("SAMPLE_RATE", SAMPLE_RATE)
          audioContext.close();
          break;
        }
        audioContext.close();
      } catch (e) {
        // Sample rate not supported
      }
    }
  };


  const handleUserInteraction = () => {
    if (!audioContextInitialized.current) {
      audioContextInitialized.current = true;
      initializeAudioContext();
      // Remove the event listeners after initialization
      window.removeEventListener('mousedown', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    }
  };

  useEffect(() => {
    // Add event listeners for user interaction
    window.addEventListener('mousedown', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);
    window.addEventListener('touchstart', handleUserInteraction);

    // Cleanup function
    return () => {
      window.removeEventListener('mousedown', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []);



  useEffect(() => {
    miliStateRef.current = miliState;
  }, [miliState]);

  useEffect(() => {
    activeMeetingIdRef.current = activeMeetingId;
    if (activeMeetingId) {
      resetStateForNewMeeting();
    }
  }, [activeMeetingId]);

  useEffect(() => {
    if (miliState === "IN_PROGRESS") {
      requestWakeLock();
    } else {
      releaseWakeLock();
    }
  }, [miliState]);

  const handleUpdateMeetingTime: HandleUpdateMeetingMaxTiming = ({
    data,
    action,
  }) => {
    if (action === "UPDATE") {
      setMeetingMaxTime(data.remaining_time);
    }
    if (action === "RESET") {
      setMeetingMaxTime(null);
    }
  };

  const resetStateForNewMeeting = () => {
    // Reset all relevant states and references for a new meeting
    packetSequenceNumberRef.current = 0;
    sentPacketsRef.current = {};
    lastAckIdRef.current = -1;
    reconnectAttemptsRef.current = 0;
    audioChunksRef.current = [];
    audioMetadataRef.current = null;
  };

  const connect = async (id, alerts=true, depth=0) => {
    if (depth >= MAX_RECONNECT_DEPTH) {
      return;
    }
    if (networkStatus === "NO_NETWORK") {
      toastEmitter.show({
        message: "Please check your internet connection",
        severity: "error",
      });
      return;
    }
    const permissionStatus = await navigator.permissions.query({
      name: "microphone" as PermissionName,
    });
    if (permissionStatus.state === "denied") {
      toastEmitter.show({
        message: "Please give microphone permission to continue",
        severity: "info",
      });
      return;
    }

    setActiveMeetingId(id);

    if (!socketRef.current || socketRef.current.readyState === WebSocket.CLOSED) {
      const url = ROUTES.WS_SOCKET(id, token);
      socketRef.current = new WebSocket(url);


      socketRef.current.onopen = () => {
        clearTimeout(connectTimeoutRef.current);
        isConnectedRef.current = true;
        sendCommand("START");
      };

      socketRef.current.onclose = (event) => {
        console.log("WebSocket disconnected", event);

        if (networkStatusRef.current === "NO_NETWORK") return;

        if (!event.wasClean || ["IN_PROGRESS", "RESUME"].includes(miliStateRef.current)) {
          const errMsg = event.reason || "Unable to connect to server";

          if (alerts){
            toastEmitter.show({
              message: errMsg,
              severity: "error",
            });

            play(AUDIO_PATH.ERROR);
          }

          Mixpanel.track(EventNames.MEETING_ALERT, {
            "Meeting ID": activeMeetingIdRef.current,
            "Alert Type": "Error",
            Category: "Socket",
            "Alert Message": errMsg,
            "Error Code": event.code,
          });

          setMiliState("RESUME");
          disconnect();

          console.log(reconnectAttemptsRef.current)
          // Improved reconnection logic
          if (!event.wasClean) {
            if (reconnectAttemptsRef.current < maxReconnectAttempts) {
              const delay = Math.min(initialReconnectDelay, (300 * 1000) - (initialReconnectDelay * reconnectAttemptsRef.current));
              console.log(
                `Attempting to reconnect in ${delay}ms (attempt ${reconnectAttemptsRef.current + 1}/${maxReconnectAttempts})`
              );
              reconnectAttemptsRef.current = reconnectAttemptsRef.current + 1;
              setMiliState("RECONNECTING"); 
              if (delay > 0) {
                connectTimeoutRef.current = setTimeout(() => {
                  if(!socketRef.current || socketRef.current.readyState === WebSocket.CLOSED) {
                    connect(id, alerts=reconnectAttemptsRef.current % 10 === 0);
                  }
                }, delay);
              }
            } else {
              console.log("Max reconnection attempts reached. Please try manually reconnecting.");
              toastEmitter.show({
                message: "Unable to reconnect. Please try again later.",
                severity: "error",
              });
              reconnectAttemptsRef.current = 0;
              setMiliState('STOPPED');
              stopRecording();
              play(AUDIO_PATH.ERROR);
            }

            return;
          }

          stopRecording();
        } else {
          disconnect();
          stopRecording();
        }
      };

      socketRef.current.onerror = (error: ErrorEvent) => {
        console.error("WebSocket connection error: ", error);

        const errMsg = error.message || "Socket connection error";

        if (alerts){
          toastEmitter.show({
            message: errMsg,
            severity: "error",
          });

          play(AUDIO_PATH.ERROR);
        }

        Mixpanel.track(EventNames.MEETING_ALERT, {
          "Meeting ID": activeMeetingIdRef.current,
          "Alert Type": "Error",
          Category: "Socket",
          "Alert Message": errMsg,
        });

        setMiliState("RESUME");

        disconnect();
        // stopRecording();
      };

      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.type === "TIMER") {
          handleUpdateMeetingTime({ data: data, action: "UPDATE" });
        }

        // Handle ACK from server
        if (data.type === "ACK") {
          const ackId = data.sequence_number;
          lastAckIdRef.current = ackId;

          // Remove acknowledged packets from sentPacketsRef
          for (let seq = 0; seq <= ackId; seq++) {
            delete sentPacketsRef.current[seq];
          }
        }

        // Existing code
        // Start recording on 'listening' status
        if (data.type === "STATUS" && data.status === "listening") {
          stopRecording();
          startRecording();
        }

        // Disconnect on 'stopped' status
        if (data.type === "STATUS" && (data.status === "stopped" || data.status === "stopped_via_timer")) {
          disconnect();
        }

        if (data.type === "ALERT" && data.message){
          showAlert(data.message);
        }
      };
    } else {
      disconnect();
      setTimeout(() => connect(id, depth=depth+1), 1500);
    }
  };

  const showAlert = (message) => {
    toastEmitter.show({
      message: message,
      severity: "error",
    });
    play(AUDIO_PATH.ERROR);
  }

  const disconnect = () => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      isConnectedRef.current = false;
      isSocketReadyToSendData.current = false;
      setMeetingMaxTime(null)
      console.log("WebSocket disconnected");
    }
  };

  // STOP | PAUSE | UNPAUSE
  const pauseStop = (type) => {
    if (type === "STOP") {
      setMiliState(socketRef.current ? "FINALIZING_NOTES" : "RESUME"); // RESUME for offline case
      stopRecording();
      sendCommand("STOP");
    }
    if (socketRef.current) {
      if (type === "PAUSE") {
        setMiliState("RESUME");
        stopRecording();
      }
      if (type === "UNPAUSE") {
        startRecording();
      }
    }
  };

  async function startRecording() {
    isRecordingRef.current = true;

    // WebView handling
    if ((window as any).startRecording) {
      setMiliState("IN_PROGRESS");

      if (audioMetadataRef.current) {
        socketRef.current?.send(
          JSON.stringify({
            type: "AUDIO_METADATA",
            audio_metadata: audioMetadataRef.current
          })
        );
        resendUnacknowledgedPackets();
      }
      isSocketReadyToSendData.current = true;
      (window as any).startRecording();
      return;
    }

    try {
      await sleep(1000); // 1 second delay

      var CHANNELS = 1;
      var SAMPLE_WIDTH = 2; // bytes

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: CHANNELS,
          autoGainControl: false,
          echoCancellation: false,
          noiseSuppression: false,
        },
      });

      streamRef.current = stream;

      socketRef.current?.send(
        JSON.stringify({
          type: "AUDIO_METADATA",
          audio_metadata: { sample_rate: SAMPLE_RATE, channels: CHANNELS, sample_width: SAMPLE_WIDTH },
        })
      );

      // Resend unacknowledged packets on reconnection
      resendUnacknowledgedPackets();
      isSocketReadyToSendData.current = true;


      const newAudioContext = new AudioContext({ sampleRate: SAMPLE_RATE });
      const source = newAudioContext.createMediaStreamSource(stream);
      const newScriptProcessor = newAudioContext.createScriptProcessor(
        16384,
        1,
        1
      );

      source.connect(newScriptProcessor);
      newScriptProcessor.connect(newAudioContext.destination);

      newScriptProcessor.onaudioprocess = (audioProcessingEvent) => {
        const inputBuffer = audioProcessingEvent.inputBuffer;
        const inputData = inputBuffer.getChannelData(0);

        // Convert Float32Array to Int16Array for more efficient transmission
        const pcmData = new Int16Array(inputData.length);
        for (let i = 0; i < inputData.length; i++) {
          pcmData[i] = Math.max(
            -32768,
            Math.min(32767, Math.floor(inputData[i] * 32768))
          );
        }

        const audioBase64 = arrayBufferToBase64(pcmData.buffer);

        // Assign sequence number
        const sequenceNumber = packetSequenceNumberRef.current++;
        console.log(sequenceNumber);
        // Store the packet
        sentPacketsRef.current[sequenceNumber] = audioBase64;

        // Ensure the buffer size does not exceed the maximum allowed size
        if (Object.keys(sentPacketsRef.current).length > maxPacketBufferSize) {
          const oldestSequenceNumber = Math.min(...Object.keys(sentPacketsRef.current).map(Number));
          delete sentPacketsRef.current[oldestSequenceNumber];
        }

        // Send the packet with sequence number
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN && isSocketReadyToSendData.current) {
          socketRef.current.send(
            JSON.stringify({ type: "AUDIO", audio_data: audioBase64, sequence_number: sequenceNumber })
          );
        }
      };

      audioContextRef.current = newAudioContext
      scriptProcessorRef.current = newScriptProcessor
      // setAudioContext(newAudioContext);
      // setScriptProcessor(newScriptProcessor);

      setMiliState("IN_PROGRESS");
    } catch (error) {
      console.error("Error starting recording:", error);
      setMiliState("RESUME");
      disconnect();
      if (error.message === "Permission denied") {
        toastEmitter.show({
          message: "Please give microphone permission to continue",
          severity: "info",
        });
        return;
      }
      toastEmitter.show({
        message: error.message,
        severity: "error",
      });
    }
  }

  function stopRecording() {
    isRecordingRef.current = false;

    // WebView handling
    if ((window as any).stopRecording) {
      (window as any).stopRecording();
      return;
    }

    if (scriptProcessorRef.current) {
      scriptProcessorRef.current.disconnect()
      scriptProcessorRef.current = null;
      // scriptProcessor.disconnect();
      // setScriptProcessor(null);
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioChunksRef.current = null
      // setAudioContext(null);
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  }

  function sendCommand(command) {
    if (!socketRef.current) return;
    socketRef.current.send(JSON.stringify({ type: "COMMAND", command: command }));
  }

  // Function to resend unacknowledged packets on reconnection
  const resendUnacknowledgedPackets = () => {
    console.log("Resending existing ones for now which don't have any ack");
    const packets = sentPacketsRef.current;
    const keys = Object.keys(packets)
      .map(Number)
      .sort((a, b) => a - b);
    const startSequence = lastAckIdRef.current + 1;
    console.log(`Sending all things after ${startSequence}`)
    for (const seq of keys) {
      if (seq >= startSequence) {
        const packet = packets[seq];
        socketRef.current?.send(
          JSON.stringify({ type: "AUDIO", audio_data: packet, sequence_number: seq })
        );
      }
    }
  };

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        setActiveMeetingId(null);
        socketRef.current.close();
      }
    };
  }, []);

  // WebView handling
  useEffect(() => {
    // Define the function and expose it to the window object
    (window as any).onNewRecording = (audioData, isMetaData: boolean = false) => {
      let audioObj;

      if (isMetaData) {
        audioObj = { type: "AUDIO_METADATA", audio_metadata: JSON.parse(audioData) };
        audioMetadataRef.current = JSON.parse(audioData);
        socketRef.current?.send(JSON.stringify(audioObj));
      } else {
        // Assign sequence number
        const sequenceNumber = packetSequenceNumberRef.current++;
        // Store the packet
        sentPacketsRef.current[sequenceNumber] = audioData;

        // Ensure the buffer size does not exceed the maximum allowed size
        if (Object.keys(sentPacketsRef.current).length > maxPacketBufferSize) {
          const oldestSequenceNumber = Math.min(...Object.keys(sentPacketsRef.current).map(Number));
          delete sentPacketsRef.current[oldestSequenceNumber];
        }

        audioObj = { type: "AUDIO", audio_data: audioData, sequence_number: sequenceNumber };
        if (isSocketReadyToSendData.current) {
          socketRef.current?.send(JSON.stringify(audioObj));
        }

      }

    };

    return () => {
      // Cleanup: remove the function from the window object when the component unmounts
      delete (window as any).onNewRecording;
    };
  }, []);

  const handleExtendMeeting = () => {
    sendCommand("EXTEND");
    setMeetingExtended(true);
    setTimeout(() => {
      setMeetingExtended(false);
    }, 5000)
  };

  return (
    <SocketContext.Provider
      value={{
        connect,
        disconnect,
        startRecording,
        sendCommand,
        isConnectedRef,
        activeMeetingId,
        pauseStop,
        miliState,
        setMiliState,
        setActiveMeetingId,
        activeMeetingIdRef,
        socketRef,
        meetingMaxTime,
        meetingExtended,
        handleExtendMeeting,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};